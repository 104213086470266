import React from 'react'

import * as styles from './Solutions.module.scss'
import close from './assets/close.svg'
import done from './assets/done.svg'
import solution1 from './assets/solutions1.png'
import solution2 from './assets/solutions2.png'
import { getTranslate } from 'react-localize-alias'

export const Solutions = () => {
  return (
    <section className={styles.solutions}>
      <h3 className={styles.title}>{getTranslate('old.solutions.title')}</h3>
      <h3 className={styles.subtitle}>{getTranslate('old.solutions.subtitle')}</h3>

      <main className={styles.main}>
        <div className={styles.card}>
          <img className={styles.image} src={solution1} alt="solution" />
          <div className={styles.info}>
            <div className={styles.description}>
              <p className={styles.text}>{getTranslate('old.solutions.cons.point1')}</p>
              <img src={close} alt="close" />
            </div>
            <div className={styles.description}>
              <p className={styles.text}>{getTranslate('old.solutions.cons.point2')}</p>
              <img src={close} alt="close" />
            </div>
            <div className={styles.description}>
              <p className={styles.text}>{getTranslate('old.solutions.cons.point3')}</p>
              <img src={close} alt="close" />
            </div>
            <div className={styles.description}>
              <p className={styles.text}>{getTranslate('old.solutions.cons.point4')}</p>
              <img src={close} alt="close" />
            </div>
          </div>
        </div>
        <h3 className={styles.middle}>{getTranslate('old.solutions.subtitle')}</h3>
        <div className={styles.card}>
          <img className={styles.image} src={solution2} alt="solution" />
          <div className={styles.info}>
            <div className={styles.description}>
              <img src={done} alt="done" />
              <p className={styles.text}>{getTranslate('old.solutions.pros.point1')}</p>
            </div>
            <div className={styles.description}>
              <img src={done} alt="done" />
              <p className={styles.text}>{getTranslate('old.solutions.pros.point2')}</p>
            </div>
            <div className={styles.description}>
              <img src={done} alt="done" />
              <p className={styles.text}>{getTranslate('old.solutions.pros.point3')}</p>
            </div>
            <div className={styles.description}>
              <img src={done} alt="done" />
              <p className={styles.text}>{getTranslate('old.solutions.pros.point4')}
              </p>
            </div>
          </div>
        </div>
      </main>
    </section>
  )
}
