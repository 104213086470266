// extracted by mini-css-extract-plugin
export var wrapper = "Solutions-module--wrapper--d642e";
export var container = "Solutions-module--container--82f4a";
export var solutions = "Solutions-module--solutions--54c29";
export var title = "Solutions-module--title--32719";
export var subtitle = "Solutions-module--subtitle--7ecb5";
export var main = "Solutions-module--main--0ff0b";
export var middle = "Solutions-module--middle--87f67";
export var card = "Solutions-module--card--eedf5";
export var image = "Solutions-module--image--75c59";
export var info = "Solutions-module--info--2d28c";
export var description = "Solutions-module--description--65c67";
export var text = "Solutions-module--text--ee472";