/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import {
  Header,
  Hero,
  Presentation,
  Rights,
  Patents,
  RoadMap,
  Visualization,
  News,
  Invest,
  Live,
  Stats,
  Committee,
  Invitation,
  Footer,
  Energy,
  Interview,
  Solutions,
  Renovations,
  HeroGorod,
  VisualizationGorod,
} from '../../../../share/new/components'
import 'swiper/css'
import {
  News as NewsOld,
  Patents as PatentsOld,
  Streams,
  Committee as CommitteeOld,
  Visualization as VisualizationOld,
} from '@components/sections'
import { Circles } from '../../../../share/new/widgets'
import { AppContext } from '@components/App'
import {
  useGorodNews,
  useGorodPatents,
  useVodaNews,
  useVodaPatents,
  useGorodVisualizations,
  useVodaVisualizations,
} from '@graphql'
import { Content, getContent } from '@utils'
import { getApi } from '../../../../../../api/_utils'
import { getNewsData } from '../../../../../../api/news'
import { Modal } from '@components/App/Modal'
import { Typography } from '@UIKit'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import * as styles from './Root.module.scss'

export const weterStreams = {
  dubai: {
    id: 0,
    name: 'landing.streams.1.title',
    preview: 'officeDubai',
    description: 'streams.1.description',
    address: 'streams.1.address',
    timezone: 4,
    time: '09:00 AM - 06:00 PM',
    url: 'https://www.youtube.com/embed/FxEKqSp3zGM?rel=0&controls=0',
    live: false,
    technical: false,
  },
  vladivostok: {
    id: 1,
    name: 'landing.streams.2.title',
    preview: 'streamVladivostok',
    description: 'streams.2.description',
    address: 'streams.2.address',
    timezone: 10,
    url: 'https://video.sibset.ru/site/embed.html?id=3086&html5=true&logo=blank_screenshot.png&w=100%&h=100%&autostart=true',
    live: true,
    technical: false,
    // style: { transform: 'scale(1.15) translateY(32px)' },
  },
  novosibirsk: {
    id: 2,
    name: 'landing.streams.3.title',
    preview: 'streamNSKRoof',
    description: 'streams.3.description',
    address: 'streams.3.address',
    timezone: 7,
    url: 'https://video.sibset.ru/site/embed.html?id=3074&html5=true&logo=blank_screenshot.png&w=100%&h=100%&autostart=true',
    live: true,
    technical: true,
  },
}

export const Root = () => {
  const { langCode } = useContext(AppContext)
  const news = useGorodNews()
  const patents = useGorodPatents()

  const [newsModal, setNewsModal] = useState(false)
  const [selectedNews, setSelectedNews] = useState(0)
  const [selectedDescription, setSelectedDescription] = useState<Content[]>([])

  const [investorCount, setInvestorCount] = useState(30014)

  useEffect(() => {
    const getDescription = async () => {
      const description: Content[] = await getNewsData(news[selectedNews].id).then(({ data }) => data.content)
      setSelectedDescription(description)
    }

    getDescription()
  }, [selectedNews])

  useEffect(() => {
    const getInvestorCount = async () => {
      const count = await fetch(getApi('projects/projects/grd/investorcount'))
        .then((data) => data.json())
        .then(({ data }) => data.count)

      setInvestorCount(count)
    }

    getInvestorCount()
  }, [])

  const openNewsModal = () => {
    setNewsModal(true)
  }

  const nextNews = () => {
    setSelectedNews((state) => (state + 1) % news.length)
  }
  const prevNews = () => {
    setSelectedNews((state) => (state - 1 < 0 ? news.length - 1 : state - 1))
  }

  return (
    <div id="main" className="wrapper">
      <Circles />
      <div className="container">
        <Header />
      </div>
      <HeroGorod
        selectedNews={news[selectedNews]}
        changeSelectedNews={{ prevNews, nextNews }}
        openNewsModal={openNewsModal}
      />
      <Solutions />
      <Renovations />
      <VisualizationGorod />
      <div className="container">
        <Interview />
        <Rights />
        <PatentsOld patents={patents} />
        <RoadMap />
      </div>
      <News news={news} setSelectedNews={setSelectedNews} openNewsModal={openNewsModal} />
      <div className="container">
        {/* <Live /> */}
        <Streams streams={[weterStreams.dubai, weterStreams.vladivostok, weterStreams.novosibirsk]} />
        <Stats variant="gorod" />
        <Invest />
      </div>
      <Committee />
      <Invitation investorCount={investorCount} />
      <Footer variant="gorod" />

      <Modal className={styles.news} isOpen={newsModal} onRequestClose={() => setNewsModal(false)}>
        {news[selectedNews].mainImage ? (
          <GatsbyImage image={news[selectedNews].mainImage} alt="" />
        ) : (
          <StaticImage
            src="../../../../assets/images/weter/newsPoster.jpg"
            alt="WETER LOGO"
            placeholder="blurred"
            aspectRatio={1.77} // 16 / 9
          />
        )}
        <div className={styles.text}>
          <Typography
            className={styles.title}
            size={32}
            dangerouslySetInnerHTML={{ __html: getContent(news[selectedNews].title) }}
          />
          <Typography
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: getContent(selectedDescription).replaceAll(/<img[^>]*>|<hr[^>]*>[\s\S]*/gm, ''),
            }}
          />
        </div>
      </Modal>
    </div>
  )
}
