// extracted by mini-css-extract-plugin
export var wrapper = "Renovations-module--wrapper--dbc21";
export var container = "Renovations-module--container--78aed";
export var renovations = "Renovations-module--renovations--30fae";
export var title = "Renovations-module--title--b76bf";
export var subtitle = "Renovations-module--subtitle--7031c";
export var inner = "Renovations-module--inner--eb3b2";
export var paragraph = "Renovations-module--paragraph--74942";
export var before = "Renovations-module--before--05be2";
export var after = "Renovations-module--after--35c52";
export var background = "Renovations-module--background--bfe58";
export var card = "Renovations-module--card--9a80c";
export var content = "Renovations-module--content--1edd8";
export var name = "Renovations-module--name--f5f0c";
export var distance = "Renovations-module--distance--956ee";
export var area = "Renovations-module--area--44f3e";