// extracted by mini-css-extract-plugin
export var VideoPresentationBg = "Root-module--VideoPresentationBg--b7d09";
export var video = "Root-module--video--2c8a3";
export var videoMap = "Root-module--videoMap--da45b";
export var news = "Root-module--news--0229d";
export var text = "Root-module--text--7001e";
export var title = "Root-module--title--31070";
export var content = "Root-module--content--b121a";
export var form = "Root-module--form--12b29";
export var wrapper = "Root-module--wrapper--4c906";
export var subtitle = "Root-module--subtitle--40982";
export var inputs = "Root-module--inputs--3cfef";
export var links = "Root-module--links--ce5f7";
export var item = "Root-module--item--47277";
export var icon = "Root-module--icon--e1a3d";
export var social = "Root-module--social--8e456";