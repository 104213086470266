// extracted by mini-css-extract-plugin
export var wrapper = "Visualization-module--wrapper--c97cc";
export var container = "Visualization-module--container--a1409";
export var visualization = "Visualization-module--visualization--25f85";
export var card = "Visualization-module--card--2fe89";
export var image = "Visualization-module--image--e518a";
export var player = "Visualization-module--player--c04fd";
export var play = "Visualization-module--play--1d01e";
export var info = "Visualization-module--info--8d45e";
export var title = "Visualization-module--title--449d0";
export var flag = "Visualization-module--flag--2a3cc";