import React from 'react'

import * as styles from './Renovations.module.scss'
import distanceImage from './assets/distance.svg'
import dubaiBefore from './assets/dubaiBefore.png'
import delhiBefore from './assets/delhiBefore.png'
import moscowBefore from './assets/moscowBefore.png'
import dubaiAfter from './assets/dubaiAfter.png'
import delhiAfter from './assets/delhiAfter.png'
import moscowAfter from './assets/moscowAfter.png'
import { getTranslate } from 'react-localize-alias'

interface ICard {
  name: string
  image: string
  distance: string
}

const Card = ({ name, image, distance }: ICard) => {
  return (
    <>
      <img className={styles.background} src={image} alt="" />
      <div className={styles.card}>
        <div className={styles.content}>
          <p className={styles.name}>{getTranslate(name)}</p>
          <div className={styles.distance}>
            <p>500m</p>
            <img src={distanceImage} alt="" />
            <p>2000ft</p>
          </div>
          <p className={styles.area}>
            {distance} km<span>2</span>
          </p>
        </div>
      </div>
    </>
  )
}

export const Renovations = () => {
  const items = {
    before: [
      {
        name: 'technology.card1.city',
        image: dubaiBefore,
        distance: '35',
      },
      {
        name: 'technology.card2.city',
        image: delhiBefore,
        distance: '64',
      },
      {
        name: 'technology.card3.city',
        image: moscowBefore,
        distance: '75',
      },
    ],
    after: [
      {
        name: 'technology.card1.city',
        image: dubaiAfter,
        distance: '5',
      },
      {
        name: 'technology.card2.city',
        image: delhiAfter,
        distance: '9',
      },
      {
        name: 'technology.card3.city',
        image: moscowAfter,
        distance: '11',
      },
    ],
  }

  return (
    <div className={styles.renovations}>
      <h3 className={styles.title}>{getTranslate('weter.map.description', { missOnAlias: true })}</h3>
      <h3 className={styles.subtitle}>{getTranslate('technology.title')}</h3>

      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <p className={styles.paragraph}>{getTranslate('technology.subtitle1')}</p>
          {items.before.map((item, index) => (
            <div className={styles.before} key={index}>
              <Card key={item.distance} {...item} />
            </div>
          ))}
        </div>
        <div className={styles.inner}>
          <p className={styles.paragraph}>{getTranslate('technology.subtitle2')}</p>
          {items.after.map((item, index) => (
            <div className={styles.after} key={index}>
              <Card key={item.distance} {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
