/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import { Root as RootOld } from '../../common/pages/gorod'
import { Root } from '../../common/pages/new/gorod'

const IndexPage = () => {
  return (
    <>
      {/* <RootOld /> */}
      <Root />
    </>
  )
}

export default IndexPage
