// extracted by mini-css-extract-plugin
export var wrapper = "Hero-module--wrapper--138c8";
export var container = "Hero-module--container--75e3b";
export var hero = "Hero-module--hero--acfff";
export var inner = "Hero-module--inner--25e89";
export var info = "Hero-module--info--48f85";
export var logo = "Hero-module--logo--b5e3a";
export var title = "Hero-module--title--7951a";
export var card = "Hero-module--card--ba654";
export var date = "Hero-module--date--8c5e0";
export var main = "Hero-module--main--78eb5";
export var mainText = "Hero-module--mainText--f5d80";
export var bottom = "Hero-module--bottom--1cd48";
export var stats = "Hero-module--stats--654ea";
export var views = "Hero-module--views--d4624";
export var likes = "Hero-module--likes--6b19d";
export var link = "Hero-module--link--e9185";
export var navigation = "Hero-module--navigation--cf6c0";
export var prev = "Hero-module--prev--6454a";
export var next = "Hero-module--next--5a244";
export var navLine = "Hero-module--navLine--5ce02";
export var img = "Hero-module--img--43edd";