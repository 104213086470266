import React from 'react'

import * as styles from './Visualization.module.scss'
import flagBahrain from './assets/flagBahrain.svg'
import flagRussia from './assets/flagRussia.svg'
import flagUae from './assets/flagUae.svg'
import flagUsa from './assets/flagUsa.svg'
import flagChina from './assets/flagChina.svg'
import flagIndia from './assets/flagIndia.svg'
import bahrain from './assets/bahrain.png'
import russia from './assets/russia.png'
import uae from './assets/uae.png'
import play from './assets/play.svg'
import { SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types'

import { Slider } from '../../widgets'
import { getTranslate } from 'react-localize-alias'

const items = [
  { image: bahrain, title: 'visualization1', flag: flagBahrain },
  { image: russia, title: 'visualization2', flag: flagRussia },
  { image: uae, title: 'visualization3', flag: flagUae },
  { image: bahrain, title: 'visualization4', flag: flagUsa },
  { image: russia, title: 'visualization5', flag: flagChina },
  { image: uae, title: 'visualization6', flag: flagIndia },
]

const sliderConfig: SwiperOptions = {
  spaceBetween: 10,
}

export const VisualizationGorod = () => {
  return (
    <section className={styles.visualization}>
      <Slider config={sliderConfig}>
        {items.map((item, index) => (
          <SwiperSlide key={index} className={styles.card}>
            <img className={styles.image} src={item.image} alt="" />
            <div className={styles.player}>
              <div className={styles.play}>
                <img src={play} alt="play" />
              </div>
              <div className={styles.info}>
                <h3 className={styles.title}>{getTranslate(item.title)}</h3>
                <img src={item.flag} alt="play" className={styles.flag} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Slider>
    </section>
  )
}
